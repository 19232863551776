.netpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.4s, top 0.4s, visibility 0.4s, opacity 0.4s;
  z-index: 101;
}
.netpopup.open-popup {
  visibility: visible;
  top: 55%;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.modalwrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.4s, top 0.4s, visibility 0.4s, opacity 0.4s;
  z-index: 101;
}
.modalwrapper.open-popup {
  visibility: visible;
  opacity: 0.5;
}
.modalcont {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31rem;
  background: white;
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;
  padding: 0 1rem 3rem;
  color: #333;
}

.netpopup img {
  width: 100px;
  margin-top: -50px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.textsidecont {
  padding: 0 2rem;
}
.netpopup h2 {
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0 2rem;
}
.netpopup p {
  font-size: 1.4rem;
  color: gray;
}
.netpopup button {
  width: 100%;
  margin-top: 2rem;
  font-weight: 500;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.4s;
}
.ClosesvgCont {
  width: 100%;
  display: flex;
  justify-content: right;
}
.ClosesvgCont svg {
  width: 3rem;
  height: 100%;
  display: block;
  margin: 1rem 0;
  cursor: pointer;
}

.NoInternetSvgCont {
  width: 100%;
  height: 15rem;
  margin-bottom: 2rem;
}
.NoInternetSvgCont svg {
  width: 100%;
  height: 100%;
  display: block;
}
