/* -----common css----- */
footer {
  user-select: none;
  width: 100%;
}
/* ------footer top area----- */
.optinouter {
  max-width: 118.4rem;
  margin-left: auto;
  margin-right: auto;
}
.newsletter {
  color: var(--dark-gray);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0.8rem;
}
.optinformcont {
  width: 50%;
  padding: 2rem 2rem 0rem;
  background-color: white;
  border-radius: 0.4rem;
}
.optin-form {
  background-color: #1456a3;
  padding: 46px 70px;
  border-radius: 0.4rem;
  -ms-box-shadow: 0 40px 50px rgba(2, 29, 26, 0.14);
  -o-box-shadow: 0 40px 50px rgba(2, 29, 26, 0.14);
  box-shadow: 0 40px 50px rgba(2, 29, 26, 0.14);
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  /* padding: 2.5rem; */
  /* padding-bottom: 0.5rem; */
  width: 100%;
}
.optin-form h3 {
  margin: 0;
  /* padding-bottom: 1rem; */
  font-weight: 400;
  color: white;
  font-size: 3rem;
}
.optin-form .error-message {
  text-align: left;
  padding: 0.5rem 0rem;
  width: calc(70% + 13.3rem);
}
.emptycont {
  height: 2.5rem;
  display: flex;
  justify-content: center;
}
.errcont {
  height: 2.5rem;
  display: flex;
}
.footerrow {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.optin-input {
  width: 70%;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  padding: 0 1rem;
  font-size: var(--fontsize-emedium);
  color: var(--dark-gray);
}
.optin-input:focus-visible,
.optin-input:active {
  outline: 0 !important;
  border: 0 !important;
}
.optin-input::placeholder {
  font-size: 1.3rem;
}
.optinbtn {
  display: inline-block;
  padding: 0.57rem 3.5rem;
  margin-left: 0.7rem;
  border-radius: 0.4rem;
  border: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  background-color: var(--org-primary);
  cursor: pointer;
  position: relative;
  font-size: var(--fontsize-emedium);
}
.optinbtn:hover {
  background-color: var(--org-hover);
}
.optinbtn:active {
  background-color: var(--org-active);
}
.optinbtn:after {
  content: "";
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(10);
  transition: transform 1s, opacity 1s;
  pointer-events: none;
  background: radial-gradient(circle, #fff 10%, transparent 0) 50%;
}
.optinbtn:focus:active:after {
  opacity: 0.2;
  transform: scale(0);
  transition: 0s;
}

.error-message {
  color: red;
  font-size: 12px;
}

/* -------footer main / center area-------  */
/* .footerouter {
  background: linear-gradient(90deg, #060062, #000000);
} */
.footercol {
  max-width: 118.4rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.center-footer {
  display: grid;
  grid-template-columns: 2fr 1fr 0.9fr 1.1fr;
  align-items: center;
  width: 100%;
  color: var(--dark-gray);
  padding: 2rem 0;
}
.footer-logo-side {
  width: 100%;
  padding: 0 2rem;
}
.footer-logo {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 2.5rem;
}
.footer-logo svg {
  fill: var(--logotxt-footer-gray);
  width: 10rem;
  margin-left: 1rem;
  height: auto;
  display: block;
}
.footerdemotxt {
  font-size: 1.7rem;
  color: gray;
  font-weight: 600;
}
.footer-site-desc {
  margin-bottom: 3rem;
}
.footer-site-desc p {
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.footer-social-icon {
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;
  width: 100%;
}
.socialhover {
  display: flex;
  align-items: center;
  justify-content: start;
  /* gap: 5rem; */
  padding-left: 2rem;
  padding-right: 2rem;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s linear;
  font-size: var(--fontsize-small);
}

.socialhover svg {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  fill: var(--dark-gray);
}

.socialhover svg {
  transition: all 0.2s linear;
}
.socialhover:hover svg {
  fill: var(--org-hover);
}
.socialhover span {
  color: var(--dark-gray);
  transition: all 0.2s linear;
}
.socialhover:hover span {
  color: var(--org-hover);
}
.footer-trust-badges {
  display: flex;
  width: 100%;
}
.footer-trust-badges img {
  width: 7.5rem;
  aspect-ratio: 2.6/2;
  margin-right: 2.7rem;
}
.footer-trust-badges img.ss {
  width: 9.5rem;
}

.col-heading {
  color: #000000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: var(--fontsize-emedium);
  width: 100%;
  padding: 1.5rem 0;
}

.col-li {
  font-size: var(--fontsize-eemedium);
  display: flex;
  font-weight: 400;
  color: var(--dark-gray);
  padding: 0.2rem 0;
  white-space: normal;
  line-height: 3.2rem;
  transition: color 0.3s;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}
.col-li:hover {
  color: var(--org-primary);
}
.col-ul ul li {
  list-style: none;
}
.footer-colum {
  padding-left: 2rem;
  align-self: start;
}
.col-li .mailsvgcont {
  width: 2rem;
  height: auto;
  display: inline-block;
  margin-right: 0.6rem;
  fill: var(--dark-gray);
}
.col-li:hover .mailsvgcont {
  fill: var(--org-primary);
}
.helperlinkcont .col-li {
  color: var(--dark-gray);
}
/* ------footer below area------ */

.below-footer {
  padding: 1.2rem;
  border-top: 0.1rem solid var(--light-gray);
  width: 100%;
  text-align: center;
  color: var(--dark-gray);
}
.brshow {
  display: none;
}
/*****************************************
**************Responsiveness***********
*************************************** */
@media (max-width: 1100px) {
  .footer-colum {
    padding-left: 1rem;
  }
}
@media (max-width: 950px) {
  .footer-logo-side {
    padding: 0 1rem 0 2rem;
  }
}
@media (max-width: 900px) {
  .center-footer {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    row-gap: 5rem;
  }
  .footer-logo-side {
    grid-column: span 3;
  }
  .footer-colum {
    grid-row: 1;
  }
  .center-footer {
    padding: 4rem 3rem;
  }
  .optin-input {
    width: 60%;
  }
  .optin-form .error-message {
    width: calc(60% + 13.3rem);
  }
}
@media (max-width: 760px) {
  .optin-form {
    width: 90%;
  }
  .optin-form .error-message {
    width: calc(60% + 12.9rem);
  }
}
@media (max-width: 700px) {
  .socialhover {
    padding-left: 1rem;
  }
  .below-footer {
    font-size: 1.4rem;
  }
}
@media (max-width: 450px) {
  .optin-form {
    width: 100%;
    padding: 10px 20px;
    flex-direction: column;
    gap: 1rem;
  }
  .optin-form h3 {
    font-size: 2rem;
  }
  .optinformcont {
    width: 100%;
  }
  .optinformcont {
    padding: 1rem 1rem 0rem;
    background-color: transparent;
  }
  .optinbtn {
    background-color: #0f3e74;
    border: 1.5px solid white;
  }
}
@media (max-width: 400px) {
  .footer-logo-side {
    text-align: center;
  }
  .footer-social-icon,
  .footer-trust-badges {
    justify-content: center;
  }
  .optinbtn {
    padding: 0.57rem 2.5rem;
  }
  .optin-input {
    font-size: var(--fontsize-medium);
  }
  .brshow {
    display: block;
  }
  .below-footer {
    font-size: 1.2rem;
  }
  .emptycont {
    height: 4rem;
  }
  .optin-form .error-message {
    width: calc(60% + 11.3rem);
  }
}
@media (max-width: 360px) {
  .center-footer {
    padding: 2rem 1rem;
  }
}
@media (max-width: 320px) {
  .footer-logo svg:first-child {
    width: 3rem;
  }
  .footer-logo svg:last-child {
    width: 17.5rem;
  }
  .center-footer {
    row-gap: 2rem;
  }
  .footer-trust-badges img {
    margin: 0 1rem;
  }
  .socialhover {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}
